// Consultation.js
import React, { useState, useEffect } from 'react';

const Consultation = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',  // Centers the iframe horizontally
        alignItems: 'center',      // Centers the iframe vertically (optional)
        height: '100%',           // Takes up full height (optional)
        paddingTop: '20px',
        paddingBottom: '20px',
    };

    const iframeStyle = {
        width: isMobile ? '100%' : '640px',  // Adjusts width based on screen size
    };

    return (
        <div style={containerStyle}>
            <iframe 
                style={iframeStyle}
                src="https://docs.google.com/forms/d/e/1FAIpQLScwngmwXaxF6k7SFSu2DNiEkUmJ5PQIm-CZBykTWY6Lzb78ig/viewform?embedded=true" 
                width="100%"  // iframe will take up 100% width of its container
                height="1560" 
                frameborder="0" 
                marginheight="0" 
                marginwidth="0"
                title="Consultation Form"
            >
                Loading…
            </iframe>
        </div>
    );
}

export default Consultation;
