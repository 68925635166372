import React from 'react';
import './ContactPage.css';

function ContactPage() {
    const emailUser = "Abutac";
    const emailDomain = "ariseseniorplacement.com";

    return (
        <div className="unique-contact-page">
            <section className="unique-contact-intro">
                <h1>Contact Arise</h1>
                <p>We're here to help. Reach out to us using the information below.</p>
            </section>

            <section className="unique-contact-content">
                <div className="unique-visit-us">
                    <h2>Visit Us</h2>
                    <address>
                        9245 Laguna Springs Drive, Suite 200<br />
                        Elk Grove, CA 95758
                    </address>
                </div>

                <div className="unique-contact-us">
                    <h2>Contact Us</h2>
                    <p>Phone: 1-916-273-3512</p>
                    <p>Phone: 1-800-516-2771</p>
                    <p>Fax: 1-916-244-0519</p>
                    <p>Email: <a href={`mailto:${emailUser}@${emailDomain}`}>{emailUser}@{emailDomain}</a></p>
                </div>
            </section>
        </div>
    );
}

export default ContactPage;
