import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-section">
                <h4>Visit Us</h4>
                <p>9245 Laguna Springs Drive, Suite 200</p>
                <p>Elk Grove, CA 95758</p>
            </div>
            <div className="footer-section">
                <h4>Contact Us</h4>
                <p>Phone: 1-916-273-3512</p>
                <p>Phone: 1-800-516-2771</p>
                <p>Fax: 1-916-244-0519</p>
            </div>
            <div className="footer-section">
                <h4>Get Started</h4>
                <p>Ready to find the perfect living situation for your loved ones? We're here to help.</p>
                <a href="/consultation" className="cta-button">Schedule a Consultation</a>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} Arise Senior Placement. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
