import React from 'react';
import './TestimonySection.css';
import image1 from '../assets/person4.jpeg';
import image2 from '../assets/person5.jpeg';
import image3 from '../assets/person3.jpeg';

function TestimonySection() {

  const testimonies = [
    {
      image: image1,
      name: 'Maria Fernandez',
      country: 'Stockton, CA',
      quote: 'This service was life-changing, I am thankful!'
    },
    {
      image: image2,
      name: 'Robert Miller',
      country: 'Elk Grove, CA',
      quote: 'I couldn’t have found a better place for my dad. Thank you!'
    },
    {
      image: image3,
      name: 'Hana Kim',
      country: 'Davis, CA',
      quote: 'Excellent service, friendly staff, and fast placement.'
    }
  ];


  return (
    <div className='testimony-div testimony-title'>
      <h2>What Our Clients Say</h2>
      <section className="testimony-section">
      {testimonies.map((testimony, index) => (
        <div key={index} className="testimony-card">
          <img src={testimony.image} alt={testimony.name} className="testimony-image" />
          <h3 className="testimony-name">{testimony.name}</h3>
          <p className="testimony-country">{testimony.country}</p>
          <blockquote className="testimony-quote">
            {testimony.quote}
          </blockquote>
        </div>
      ))}
    </section>
    </div>
    
  );
}

export default TestimonySection;
