import React from 'react';
import './AboutPage.css';
import image1 from '../assets/pic-2.jpg';


function AboutPage() {
    return (
        <div className="unique-about-page">
            <section className="unique-about-intro">
                <h1>About Arise</h1>
                <p>
                    At Arise, we are dedicated to creating a future where seniors can live with dignity 
                    and receive the care they need in a place they can call home. We understand that every 
                    individual has unique needs, which is why we make it our priority to listen first and 
                    then act, offering personalized solutions tailored to each senior’s situation.
                </p>
                <p>
                    We are not just a service; we are a compassionate companion committed to enriching the 
                    lives of the elderly, making the process stress-free for all involved.
                </p>
            </section>

            <section className="unique-section-content">
                <div className="unique-mission-text">
                    <h2>Our Mission & Vision</h2>
                    <p>
                        Our mission is to empower seniors to live with dignity and grace, in an environment 
                        where elderly care is effective, simple, and compassionate. Unlike larger, impersonal 
                        companies, we pride ourselves on forming close, one-on-one relationships with each 
                        individual we serve.
                    </p>
                    <p>
                        Our vision is to be the most trusted and respected provider of senior living solutions 
                        in the community. We achieve this by not just meeting, but exceeding the expectations 
                        of our clients through our unwavering commitment to quality, transparency, and best-in-class pricing.
                    </p>
                    <p>
                        We firmly believe that everyone deserves exceptional care at a fair price, and we 
                        stand by this conviction in every decision we make.
                    </p>
                </div>
                <div className="unique-mission-image">
                    <img src={image1} alt="About Arise" />
                </div>
            </section>

            <section className="unique-faq-section">
                <h2>Frequently Asked Questions</h2>
                <div className="unique-faq-item">
                    <h3>How do we choose the best living environment?</h3>
                    <p>We assess the individual needs of each client and match them with our network of trusted providers.</p>
                </div>

                {/* <div className="unique-faq-item">
                    <h3>What makes your pricing competitive?</h3>
                    <p>We strive to keep our operating costs low and work diligently to negotiate the best rates, so we can pass those savings onto our clients.</p>
                </div> */}

                <div className="unique-faq-item">
                    <h3>How do you ensure a personal, one-on-one approach?</h3>
                    <p>We prioritize a personalized approach for each client, assigning a dedicated care advisor who works directly with the family to meet their unique needs and preferences.</p>
                </div>

                <div className="unique-faq-item">
                    <h3>What steps are taken to ensure the safety and well-being of seniors?</h3>
                    <p>We rigorously and continually monitor all providers in our network, requiring them to meet high standards of safety, training, and compassionate care.</p>
                </div>
            </section>
        </div>
    );
}

export default AboutPage;
