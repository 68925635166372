import React, { useState, useEffect, useRef } from 'react';
import './InfoSections.css';
import image from '../assets/pic-4.jpg';
import image2 from '../assets/pic-5.jpg';

const InfoSections = () => {
    const [isImage1Visible, setImage1Visible] = useState(false);
    const [isImage2Visible, setImage2Visible] = useState(false);
    
    const image1Ref = useRef(null);
    const image2Ref = useRef(null);
    
    const handleScroll = () => {
        const img1 = image1Ref.current;
        const img2 = image2Ref.current;

        if (img1) {
            const rect = img1.getBoundingClientRect();
            if (rect.top <= window.innerHeight && rect.bottom >= 0) {
                setImage1Visible(true);
            }
        }

        if (img2) {
            const rect = img2.getBoundingClientRect();
            if (rect.top <= window.innerHeight && rect.bottom >= 0) {
                setImage2Visible(true);
            }
        }
    }
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);
    
    return (
        <div className="info-sections-container">
            <div className="intro-text" id='get-started'>
                Arise Senior Placement is your trusted source for senior placement and referrals.
                <br />
                With a combined experience of over 30 years in the healthcare industry, we are committed to providing exceptional service.
            </div>
            <div className="info-section">
                <img ref={image1Ref} src={image} alt="Home" className={`info-image ${isImage1Visible ? 'fade-slide-in' : ''}`} />
                <div className="info-text">
                    <h2>Free Personalized Placement Services</h2>
                    <p>We offer free personalized support services in assisting you in finding the appropriate home for your loved one. These places include:</p>
                    <ul className="service-list">
                        <li>Independent Living Homes</li>
                        <li>Continuing Care Retirement Communities</li>
                        <li>Assisted Living Homes</li>
                        <li>Board and Care Homes</li>
                        <li>Alzheimer’s Care Homes</li>
                    </ul>
                </div>
            </div>

            <div className="info-section">
                <img ref={image2Ref} src={image2} alt="Home" className={`info-image ${isImage2Visible ? 'fade-slide-in' : ''}`} />
                <div className="info-text">
                    <h2>Experience You Can Trust</h2>
                    <p>Arise Senior Placement understands that this transition for an elder and their loved ones is one of the most difficult journeys they will have to make.</p>
                    <p>Our experienced and professional staff discuss all appropriate options and will be by your side through the whole process to ensure you are comfortable and feel secure in your decision.</p>
                    <p>We take the time to learn about our client’s history, preferences and special requests to better identify the ideal living environment for their needs. Our support continues through the entire process, including site visits and post-placement follow-ups to ensure the living environment was the right choice for them.</p>
                </div>
            </div>
        </div>
    );
}

export default InfoSections;
