import React from 'react';
import './LandingComponent.css'; 
import backgroundImage from '../assets/pic-1.jpg'; 

function LandingComponent() {
    const handleGetStartedClick = () => {
        const element = document.getElementById('get-started');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="landing-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
            {/* Text Section */}
            <div className="text-section">
                <h1 className="title">Peace of Mind in Every Placement</h1>
                <p className="sub-title">
                    We offer free, personalized services that help find the right living environment for you or your loved one.
                </p>
                <button className="assessment-button" onClick={handleGetStartedClick}>Get Started</button>
            </div>
        </div>
    );
}

export default LandingComponent;
