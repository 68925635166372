import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; 
import logo from '../assets/ariselogo.png'; 

function Navbar() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    return (
        <nav className="navbar">
            <Link to="/">
                <img src={logo} alt="Company Logo" className="logo" />
            </Link>
            
            <ul className={`menu ${isMobileMenuOpen ? 'active' : ''}`}>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/services">Services</Link></li>
                <li><Link to="/contact-us">Contact</Link></li>
                <li><Link to="/consultation" className='concel'>Schedule Consultation</Link></li>
            </ul>

            <div className={`hamburger ${isMobileMenuOpen ? 'active' : ''}`} onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </nav>
    );
}

export default Navbar;
