// GetInfo.js
import React, { useState, useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './GetInfo.css';

function GetInfo() {
  const [isVisible, setVisible] = useState(false);
  const [state, handleSubmit] = useForm("meqbryjy");

  const handleScroll = () => {
    const section = document.getElementById('get-info-section');
    if (!section) return;
    const sectionTop = section.getBoundingClientRect().top;
    const triggerPoint = window.innerHeight - (window.innerHeight / 4);
    
    if (sectionTop <= triggerPoint) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (state.succeeded) {
    return <p>Thanks for reaching out! A Senior Care Consultant will contact you promptly.</p>;
  }

  return (
    <div id="get-info-section" className={`getInfo ${isVisible ? 'visible' : ''}`}>
      <h2>How Can We Help?</h2>
      <p>Fill out the form below and a Senior Care Consultant will contact you promptly.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input type="text" id="first-name" name="first-name" placeholder=" " required />
          <label htmlFor="first-name">First Name</label>
          <ValidationError prefix="First Name" field="first-name" errors={state.errors} />
        </div>
        <div className="form-group">
          <input type="text" id="last-name" name="last-name" placeholder=" " required />
          <label htmlFor="last-name">Last Name</label>
          <ValidationError prefix="Last Name" field="last-name" errors={state.errors} />
        </div>
        <div className="form-group">
          <input type="email" id="email" name="email" placeholder=" " required />
          <label htmlFor="email">Email</label>
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
        <div className="form-group">
          <input type="tel" id="phone-number" name="phone-number" placeholder=" " required />
          <label htmlFor="phone-number">Phone Number</label>
          <ValidationError prefix="Phone Number" field="phone-number" errors={state.errors} />
        </div>
        <div className="form-group">
          <input type="text" id="location" name="location" placeholder=" " required />
          <label htmlFor="location">Location</label>
          <ValidationError prefix="Location" field="location" errors={state.errors} />
        </div>
        <button type="submit" className="location-button" disabled={state.submitting}>Submit</button>
      </form>
    </div>
  );
}

export default GetInfo;
