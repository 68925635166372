import React from 'react';
// import Navbar from '../components/Navbar';
import LandingComponent from '../components/LandingComponent';
import InfoSections from '../components/InfoSections';
import GetInfo from '../components/GetInfo';
import TestimonySection from '../components/TestimonySection';

function LandingPage() {
    return (
        <div>
        <LandingComponent/>
        <InfoSections/>
        <TestimonySection/>
        <GetInfo/>
        </div>
    );
}

export default LandingPage;
