import React from 'react';
import { Link } from 'react-router-dom';
import './ServicesPage.css';

function ServicesPage() {
    return (
        <div className="services-page">
            <section className="services-intro">
                <h1>Our Services</h1>
                <p>Learn about our free referral and placement services.</p>
                <p>Our knowledgeable Advisors will speak with you to learn about the services you or your loved one requires by:</p>
            </section>

            <section className="section-content">
                <div className="services-grid">
                    <div className="service-card">
                        <div className="service-icon">📋</div>
                        <h4>Plan of Care</h4>
                        <p>Discussing and developing a plan of care to fit clients’ needs.</p>
                    </div>
                    <div className="service-card">
                        <div className="service-icon">🔍</div>
                        <h4>Facility Direction</h4>
                        <p>Directing you to facilities in the area that can ensure quality care is delivered.</p>
                    </div>
                    <div className="service-card">
                        <div className="service-icon">📅</div>
                        <h4>Facility Visits</h4>
                        <p>Scheduling and assisting with facility or home visits with the administrators of locations of interest.</p>
                    </div>
                    <div className="service-card">
                        <div className="service-icon">💰</div>
                        <h4>No Cost Consultation</h4>
                        <p>Provide consultation and referral service at NO COST to our clients.</p>
                    </div>
                </div>

                <div className="personalized-services">
                    <h4>Arise Senior Placement provides personalized services by offering:</h4>
                    <div className="services-grid">
                        <div className="service-card">
                            <div className="service-icon">🕐</div>
                            <h4>24/7 Client Availability</h4>
                        </div>
                        <div className="service-card">
                            <div className="service-icon">🚪</div>
                            <h4>Supervise Transition</h4>
                            <p>Supervise transition into facility.</p>
                        </div>
                        <div className="service-card">
                            <div className="service-icon">📄</div>
                            <h4>Coordinate Paperwork</h4>
                            <p>Coordinate paperwork and necessary documents to all parties.</p>
                        </div>
                        <div className="service-card">
                            <div className="service-icon">🔎</div>
                            <h4>Evaluate Facility Visits</h4>
                            <p>Evaluating and conducting facility visits on behalf of client and/or family.</p>
                        </div>
                        <div className="service-card">
                            <div className="service-icon">📞</div>
                            <h4>Communication</h4>
                            <p>Communicate with health institutions, physicians, hospice, home health, and other organizations connected to the clients plan of care.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-section">
                <h2>Let us find the perfect solution for your loved one.</h2>
                <Link to="/contact-us">
                    <button className="contact-button">Contact Us</button>
                </Link>
            </section>
        </div>
    );
}

export default ServicesPage;
